@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
html, body {
  margin: 0;
  font-family: Poppins,Helvetica Neue,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body {
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.625;
  color: #6c757d;
  text-align: left;
  background-color: #F7F9FC;
}

a, p {
  color: #6c757d;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #6c757d;
}

a.btn:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #495057;
}

h3 {
  font-size: 1.3rem;
}

h5 {
  font-size: .95rem;
  font-weight: 600;
}

#wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.main {
  display: flex;
  width: 100%;
  height: 100vh;
  min-width: 0;
  transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
  flex-direction: column;
  overflow: auto;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 60px;
}

@media (max-width: 991.98px) {
  .main {
    overflow-y: hidden;
  }
}

.navbar {
  position: relative;
  padding: .875rem 1.25rem;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  border-bottom: 0;
  box-shadow: 0 0 2rem 0 rgba(41, 48, 66, 0.1);
  background: #fff;
  z-index: 100;
}

@media (max-width: 991.98px) {
  .navbar {
    width: 100vw;
  }
}

.sidebar-toggle {
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  margin-right: 1rem;
  font-size: 24px;
}

.hamburger, .hamburger, .hamburger {
  position: relative;
  cursor: pointer;
  width: 24px;
  color: #495057;
  transition: background .1s ease-in-out,color .15s ease-in-out;
}

.hamburger:hover, .hamburger:hover, .hamburger:hover {
  color: #3f80ea;
}

.content {
  padding: 2.5rem 2.5rem 1.5rem;
  flex: 1 1;
  direction: ltr;
  overflow: auto;
}

@media (max-width: 1199.98px) {
  .content {
    padding: 1.5rem;
  }
}

@media (max-width: 991.98px) {
  .content {
    width: 100vw;
    max-width: 100vw;
  }
}

.spinner-container {
  width: 100%;
  min-height: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control-lg {
  height: calc(2.24375rem + 2px);
  padding: .35rem 1rem;
  font-size: .95rem;
  line-height: 1.625;
  border-radius: .3rem;
}

.form-group {
  margin-bottom: 1rem;
}

.btn-lg {
  padding: .35rem 1rem;
  font-size: .95rem;
  line-height: 1.625;
  border-radius: .3rem;
}

*, :after, :before {
  box-sizing: border-box;
}
/*# sourceMappingURL=index.css.map */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: .25rem;
  margin-bottom: 24px;
  box-shadow: 0 0 0.875rem 0 rgba(41, 48, 66, 0.05);
  flex: 1 1 auto !important;
  transition: all 0.25s ease-in-out;
}

.card.clickable:hover {
  box-shadow: 0 1rem 2rem #0000001a !important;
  -webkit-transform: translate3D(0, -1px, 0) scale(1.02);
          transform: translate3D(0, -1px, 0) scale(1.02);
}

.card .card-header {
  border-bottom-width: 1px;
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid transparent;
}

.card .card-header .card-title {
  font-size: .95rem;
  font-weight: 500;
  color: #495057;
}

.w-100 {
  width: 100%;
}

.chart {
  margin: auto;
  position: relative;
  width: 100%;
  min-height: 400px;
}

.no-link {
  text-decoration: none;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-body .media {
  display: flex;
  align-items: flex-start;
}

.card-body .media .media-body {
  flex: 1 1;
}

.card-body .media .icon-block {
  display: inline-block !important;
}

.card-body .media .icon-block .round {
  border-radius: 50%;
  padding: .75rem;
  width: 48px;
  height: 48px;
  font-size: 24px;
  line-height: 24px;
}

.card-body .media .icon-block .round.red {
  background-color: #F8D7DA;
  color: #DC3545;
}

.card-body .media .icon-block .round.yellow {
  background-color: #FFF3CD;
  color: #FFC107;
}

.card-body .media .icon-block .round.green {
  background-color: #D4EDDA;
  color: #28A745;
}

.card-body .media .icon-block .round.blue {
  background-color: #CCE5FF;
  color: #007BFF;
}

.float-right {
  float: right !important;
}

.table {
  width: 100%;
  margin-bottom: 0;
  color: #6c757d;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-collapse: collapse;
  --bs-table-striped-bg: transparent;
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #F4F7F9;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-striped > thead > tr {
  border: 0 !important;
  border-bottom: 2px solid #DEE6ED !important;
}

.table-striped > tbody > tr {
  border: 0 !important;
  border-bottom: 1px solid #DEE6ED !important;
}

.table-striped > tbody > tr:last-child {
  border: 0 !important;
  border-bottom: 1px solid transparent !important;
}
/*# sourceMappingURL=Card.css.map */
.sidebar {
  display: flex;
  flex-flow: column nowrap;
  min-width: 260px;
  max-width: 260px;
  transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
  direction: ltr;
  background: #293042;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
}

.sidebar.collapsed {
  margin-left: -260px;
}

@media (max-width: 991.98px) and (min-width: 1px) {
  .sidebar {
    margin-left: -260px;
  }
  .sidebar.collapsed {
    margin-left: 0px;
  }
}

.sidebar-top {
  width: 100%;
  height: 3.425rem;
  flex: none;
}

.sidebar-nav {
  padding-left: 0;
  flex: auto;
}

.sidebar-footer {
  width: 100%;
  flex: none;
  font-size: 0.8rem;
  text-align: center;
}

.clickable {
  transition: color 0.3s;
  padding-top: 5px;
  padding-bottom: 5px;
}

.clickable:hover {
  color: #e9ecef;
  transition: color 0.3s;
  cursor: pointer;
}

a.sidebar-link {
  display: block;
  padding: .625rem 1.625rem;
  font-weight: 400;
  transition: color 75ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  color: rgba(233, 236, 239, 0.5);
}

.sidebar-item .sidebar-link:hover, .sidebar-item.active > .sidebar-link {
  color: #e9ecef;
}

.sidebar-link .fas {
  margin-right: .75rem;
}
/*# sourceMappingURL=Sidebar.css.map */
